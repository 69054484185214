import React from 'react';
import ARTICLE_SLIDER from 'mocks/stadtcasino-baden-slider';

const ArticleSlider = () => {
  return (
    <>
      <html lang="en">
        <head>
          <meta charset="UTF-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <title>Stadtcasino Baden slider</title>
          <meta name="robots" content="noindex" />
        </head>
        <body>
          <div
            dangerouslySetInnerHTML={{
              __html: ARTICLE_SLIDER,
            }}
          ></div>
        </body>
      </html>
    </>
  );
};

export default ArticleSlider;
